// Fonts
@import url('http://minetopia.test/assets/fonts/minecrafter/font.css');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

:root {
    --spacing: 20px;
    --sidebarWidth: 280px;
}

@media (max-width:850px) {
    :root {
        --spacing: 10px;
    }
}

@media (max-width:650px) {
    :root {
        --spacing: 5px;
    }
}

html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0 !important;
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

#ddg-header .header-nav .nav-items .dropdown-menu {
    inset: 0 0 auto !important;
}

.dropdown-backdrop {
    display: none !important;
}

.dropdown-toggle:after {
    display: none;
}

.container {
    width: 1200px;
    padding: 0;
    max-width: calc(100% - calc(2 * var(--spacing)));
}

.body .full-width {
    padding: 0;
}

.ddg-body {
    margin-bottom: auto;
}

.body {
    margin: 0;
}

.body .content:not(.panel-body) {
    padding: 0;
    float: none;
    width: auto;
}

.panel {
    margin-bottom: var(--spacing);
}

.panel:last-child {
    margin-bottom: 0;
}

.panel .panel-heading {
    word-wrap: break-word;
}

#ddg-page,
.ddg-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
}

#ddg-page .page-sidebar,
.ddg-page .page-sidebar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 var(--sidebarWidth);
    flex: 0 0 var(--sidebarWidth);
    margin-right: var(--spacing);
}

#ddg-page .page-main,
.ddg-page .page-main {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

@media (max-width:850px) {
    #ddg-page,
    .ddg-page {
        display: block;
    }
    #ddg-page .page-sidebar,
    .ddg-page .page-sidebar {
        margin: 0 0 var(--spacing);
    }
}

.checkout .redeemed {
    font-size: 12px;
}

.checkout .redeemed td {
    padding-top: 10px;
    padding-bottom: 8px;
}

.privacyStatement label,
.privacyStatement p {
    cursor: pointer;
}

.modal-dialog {
    -webkit-transition: opacity .5s, -webkit-transform .4s;
    transition: opacity .5s, -webkit-transform .4s;
    -o-transition: transform .4s, opacity .5s;
    transition: transform .4s, opacity .5s;
    transition: transform .4s, opacity .5s, -webkit-transform .4s;
    opacity: 0;
    -webkit-transform: translate(0, 15px);
    -ms-transform: translate(0, 15px);
    transform: translate(0, 15px);
}

.modal.in .modal-dialog {
    opacity: 1;
}

.modal-footer .row:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.modal-footer .pull-left {
    padding: 0!important;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
}

.btn-gift {
    background: #b768ff;
    color: #fff;
    border-color: rgba(0, 0, 0, .15);
}

.btn-gift:focus,
.btn-gift:hover {
    background: #a058e2;
}

.btn-warning {
    background: #de8753;
    border-color: rgba(0, 0, 0, .125);
}

.btn-warning:focus,
.btn-warning:hover {
    background: #d07c49;
}

.btn-blank {
    background: 0 0;
    border: none;
    outline: 0;
}

.btn.btn-discord,
.variables .input-group-btn:first-child .btn-info {
    color: #fff;
    background: #7289da !important;
    border: 2px solid rgba(0, 0, 0, .125);
    font-weight: 800;
    font-size: 17px;
}

.btn.btn-discord:focus,
.btn.btn-discord:hover,
.variables .input-group-btn:first-child .btn-info:focus,
.variables .input-group-btn:first-child .btn-info:hover {
    background: #5e78d5 !important;
}

.variables .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
}

.variables .buttons .back,
.variables .buttons .continue {
    width: auto;
    float: none;
    display: block;
    padding: 0;
}

.variables .buttons .btn {
    padding: 0;
    height: 45px;
    line-height: 45px;
    width: 200px;
    margin: 0 5px 10px;
}

@media (max-width:500px) {
    .variables .buttons {
        margin: 0 0 2px
    }
    .variables .buttons .back,
    .variables .buttons .continue {
        -webkit-box-flex: calc(50% - 10px);
        -ms-flex: calc(50% - 10px);
        flex: calc(50% - 10px);
    }
    .variables .buttons .back {
        margin-right: 10px
    }
    .variables .buttons .btn {
        width: 100%;
        margin: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
}

.checkout .packages .fa.fa-gift {
    display: block;
    -webkit-animation: ndznGiftPulse .65s infinite linear;
    animation: ndznGiftPulse .65s infinite linear;
}

@-webkit-keyframes ndznGiftPulse {
    0%,
    100%,
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    25% {
        -webkit-transform: scale(.89);
        transform: scale(.89);
    }
    75% {
        -webkit-transform: scale(1.11);
        transform: scale(1.11);
    }
}

@keyframes ndznGiftPulse {
    0%,
    100%,
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    25% {
        -webkit-transform: scale(.89);
        transform: scale(.89);
    }
    75% {
        -webkit-transform: scale(1.11);
        transform: scale(1.11);
    }
}

.orderstatus td label {
    margin-top: 5px;
}

.modal-content button.close {
    font-size: 30px;
    text-shadow: none;
    position: relative;
    z-index: 1;
    outline: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.zoid-outlet {
    min-width: none !important;
    width: 100% !important;
}

.notification .alert {
    margin-bottom: var(--spacing)
}

@media(max-width:767.5px) {
    .modal-footer .row:first-child {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
    .modal-footer .row:first-child .pull-left {
        margin-bottom: 15px
    }
    .modal-footer .row:first-child .btn:first-child {
        margin-bottom: 5px
    }
}

.ddg-component-cart:not(.is--open) .cart-dropdown {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scaleY(.8);
    -ms-transform: scaleY(.8);
    transform: scaleY(.8);
}

.ddg-component-cart.is--open .cart-dropdown {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1)
}

.ddg-component-cart {
    position: relative
}

.ddg-component-cart .cart-dropdown {
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    text-align: left;
    position: absolute;
    z-index: 100;
    top: calc(100% + 2px);
    right: -5px;
    border: 1px solid #d2cbc8;
    background: #fff7f3;
    border-radius: 3px;
    padding: 12px;
    width: 300px;
    -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, .15);
    box-shadow: 0 3px 4px rgba(0, 0, 0, .15)
}

.ddg-component-cart .cart-dropdown:after,
.ddg-component-cart .cart-dropdown:before {
    content: "";
    position: absolute;
    display: block;
    bottom: 100%;
    right: 22px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: #fff7f3
}

.ddg-component-cart .cart-dropdown:before {
    margin-bottom: 1px;
    border-bottom-color: #d2cbc8
}

.ddg-component-cart .cart-dropdown .cart-controls {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ddg-component-cart .cart-dropdown .cart-controls .currency .val {
    margin: 0 3px 0 5px
}

.ddg-component-cart .cart-dropdown .cart-controls .logout {
    text-transform: initial;
    font-size: 13px;
    margin-left: auto
}

.ddg-component-cart .cart-dropdown .item {
    color: #333;
    padding: 8px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, .075);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ddg-component-cart .cart-dropdown .item .item-info {
    margin-right: auto
}

.ddg-component-cart .cart-dropdown .item .item-info .item-name {
    font-weight: 700;
    color: #666
}

.ddg-component-cart .cart-dropdown .item .item-info .item-price {
    color: #777;
    display: block;
    margin-top: -1px;
    font-size: 12px
}

.ddg-component-cart .cart-dropdown .item .item-remove {
    position: relative;
    top: 1px;
    color: #d9534f
}

.ddg-component-cart .cart-dropdown .cart-checkout .btn {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 12px;
    padding-left: 10px;
    padding-right: 8px
}

.ddg-component-cart .cart-dropdown .cart-checkout .btn .badge {
    border-radius: 5px;
    position: relative;
    padding: 4px 7px 5px;
    margin-right: -1px;
    font-size: 11px;
    font-weight: 900;
    text-shadow: none;
    background: rgba(0, 0, 0, .2);
    color: rgba(255, 255, 255, .7);
    top: 0
}

.ddg-component-cart .cart-dropdown .cart-empty {
    color: #555;
    padding: 20px 0 3px;
    text-align: center
}

.ddg-component-cart .cart-dropdown .cart-empty b {
    display: block;
    margin-bottom: 10px
}

.ddg-component-cart .cart-dropdown .cart-empty small {
    display: block;
    line-height: 15px
}

@media (min-width:850.5px) {
    #ddg-sidebar-nav .nav-header {
        display: none!important
    }
}

@media (max-width:850px) {
    #ddg-sidebar-nav .nav-header {
        display: block
    }
}

#ddg-sidebar-nav .nav-items {
    display: block
}

@media (max-width:850px) {
    #ddg-sidebar-nav .nav-items {
        display: none
    }
}

#ddg-sidebar-nav .nav-header {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#ddg-sidebar-nav .nav-header .nav-title {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    margin-right: auto
}

#ddg-sidebar-nav .nav-header .nav-toggle {
    width: 40px;
    height: 40px;
    line-height: 37px;
    outline: 0;
    padding: 0;
    text-align: center;
    font-size: 30px
}

#ddg-sidebar-nav.is--open .nav-header .nav-toggle .mdi-menu:before {
    content: "\F156"
}

#ddg-sidebar-nav.is--open .nav-items {
    display: block
}

#ddg-sidebar-nav .nav-items {
    padding: 10px 0
}

#ddg-sidebar-nav .nav-items,
#ddg-sidebar-nav .nav-items>li {
    list-style: none
}

#ddg-sidebar-nav .nav-items>li>a {
    display: block;
    padding: 7px 18px;
    font-weight: 700;
    color: #333;
    text-decoration: none;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s
}

#ddg-sidebar-nav .nav-items .nav-dd {
    display: none
}

#ddg-sidebar-nav .nav-items .nav-dd a {
    display: block;
    text-decoration: none;
    font-weight: 700;
    font-size: 13px;
    color: #666;
    padding: 3px 30px
}

#ddg-sidebar-nav .nav-items .dropdown.open .nav-dd {
    display: block
}

#ddg-footer {
    margin-top: 110px;
    font-size: 0;
    line-height: 0;
    background: rgba(0, 0, 0, .1)
}

.ddg-default-footer {
    margin-top: 30px;
    border-top: 1px solid #ddd
}

#ddg-footer .p-body-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative
}

#ddg-footer .footer-logo {
    position: absolute;
    top: -34px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 123px;
    height: 102px;
    background: url(https://i.imgur.com/UABSejb.png) no-repeat 50%/100% auto;
    pointer-events: none;
    opacity: .25
}

#ddg-footer .footer-left {
    padding: 35px 0;
    font-weight: 700
}

#ddg-footer .footer-left span {
    display: table;
    line-height: 18px
}

#ddg-footer .footer-left .big {
    font-size: 14px;
    color: #36443a
}

#ddg-footer .footer-left .big b {
    color: #56675b
}

#ddg-footer .footer-left .sm {
    margin-top: 4px;
    font-size: 12px;
    color: #28362c
}

#ddg-footer .footer-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto
}

#ddg-footer .footer-right a {
    text-decoration: none;
    position: relative;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 40px
}

#ddg-footer .footer-right a .mdi {
    color: #62866f;
    font-size: 22px;
    position: relative
}

#ddg-footer .footer-right a .mdi.mdi-discord {
    top: 1px;
    font-size: 20px
}

#ddg-footer .footer-right a:focus .mdi,
#ddg-footer .footer-right a:hover .mdi {
    color: #8baa96
}

#ddg-footer .footer-right a:nth-last-child(2):after {
    content: "";
    position: absolute;
    top: 1px;
    bottom: 0;
    left: 100%;
    margin: auto 0 auto 13px;
    width: 3px;
    height: 14px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: hsla(0, 0%, 100%, .05);
    pointer-events: none
}

#ddg-footer .footer-right #tm {
    display: block;
    height: 25px;
    width: 15px;
    background: url(https://i.imgur.com/ZU3PFO9.png) no-repeat;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    position: relative;
    top: -1px;
    opacity: .2;
    margin-left: 40px
}

#ddg-footer .footer-right #tm:hover {
    opacity: .5
}

#ddg-footer .footer-links {
    display: block;
    text-align: center;
    padding-bottom: 15px
}

#ddg-footer .footer-left span {
    display: table;
    line-height: 18px
}

#ddg-footer .footer-left .big {
    font-size: 14px;
    color: #36443a
}

#ddg-footer .footer-left .big b {
    color: #56675b
}

#ddg-footer .footer-left .sm {
    margin-top: 4px;
    font-size: 12px;
    color: #28362c
}

#ddg-footer .footer-links a {
    display: inline-block;
    height: 38px;
    line-height: 38px;
    font-size: 13px;
    padding: 0 15px;
    color: #56675b;
    font-weight: 700;
    text-decoration: none
}

#ddg-footer .footer-links a:focus,
#ddg-footer .footer-links a:hover {
    color: #738a7a
}

.ddg-default-footer .container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ddg-default-footer span {
    color: #333
}

.ddg-default-footer span small {
    display: block;
    color: #666
}

.ddg-default-footer #tm {
    display: block;
    height: 25px;
    width: 15px;
    background: no-repeat;
    background-size: 100% auto;
    position: relative;
    top: 1px;
    opacity: .4;
    margin-left: auto
}

.ddg-default-footer #tm:hover {
    opacity: .8
}

@media (max-width:850px) {
    .ddg-default-footer {
        margin-top: 0
    }
}

@media (max-width:600px) {
    .ddg-default-footer .container {
        padding-left: 10px;
        padding-right: 10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center
    }
    .ddg-default-footer span {
        display: block;
        line-height: 16px
    }
    .ddg-default-footer span small {
        margin-top: 5px
    }
    .ddg-default-footer #tm {
        margin: 20px auto -3px
    }
}

.panel.panel-default .panel-body.panel-body--category-images {
    padding: 10px
}

#ddg-packages-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -5px -10px
}

#ddg-packages-images .package {
    margin: 0 5px 10px;
    padding: 12px 0 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(25% - 10px);
    flex: 0 0 calc(25% - 10px);
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    text-align: center;
    position: relative
}

@media (max-width:1160px) {
    #ddg-packages-images .package {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(33.33333% - 10px);
        flex: 0 0 calc(33.33333% - 10px)
    }
}

@media (max-width:960px) and (min-width:850.5px),
(max-width:650px) {
    #ddg-packages-images .package {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(50% - 10px);
        flex: 0 0 calc(50% - 10px)
    }
}

@media (max-width:460px) {
    #ddg-packages-images .package {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% - 10px);
        flex: 0 0 calc(100% - 10px)
    }
}

#ddg-packages-images .package .package-image {
    width: 180px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    cursor: pointer
}

#ddg-packages-images .package .package-image img {
    width: 100%;
    height: auto
}

#ddg-packages-images .package .package-info {
    margin-top: 10px
}

#ddg-packages-images .package .package-name {
    font-weight: 700;
    display: block
}

#ddg-packages-images .package .package-expires {
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: table;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #eee;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    pointer-events: none
}

#ddg-packages-images .package .package-price {
    display: block;
    margin: 5px 0;
    font-weight: 700;
    font-size: 13px
}

#ddg-packages-images .package .package-price .price-discount {
    font-size: .85em;
    text-decoration: line-through
}

#ddg-packages-images .package .btn {
    font-weight: 700;
    font-size: 12px
}

#ddg-packages-list .list-item {
    border: 1px solid #eee;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    position: relative
}

#ddg-packages-list .list-item:not(:last-child) {
    margin-bottom: 10px
}

#ddg-packages-list .list-item .item-main {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

#ddg-packages-list .list-item .item-name {
    font-weight: 700;
    font-size: 15px;
    display: block;
    max-width: 60%
}

#ddg-packages-list .list-item .item-expires {
    position: absolute;
    top: -4px;
    right: -4px;
    display: inline-block;
    pointer-events: none;
    border: 2px solid rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
    background: #eee;
    padding: 2px 6px 3px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 11px
}

#ddg-packages-list .list-item .item-price {
    margin-left: auto;
    padding-right: 15px
}

#ddg-packages-list .list-item .item-price .price-discount {
    text-decoration: line-through;
    font-size: .9em
}

#ddg-packages-list .list-item .price-free {
    white-space: nowrap
}

#ddg-packages-list .list-item .btn {
    font-weight: 700
}

@media (max-width:600px) {
    #ddg-packages-list .list-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center
    }
    #ddg-packages-list .list-item .item-name {
        max-width: 300px
    }
    #ddg-packages-list .list-item .item-price {
        margin: 10px 0;
        padding: 0
    }
}

.panel.panel-default.ddg-checkout-packages .panel-body {
    padding: 0
}

.ddg-checkout-packages .package:not(:last-child) {
    border-bottom: 1px solid #e6e6e6
}

.ddg-checkout-packages .package-container.main,
.ddg-checkout-packages .package-left,
.ddg-checkout-packages .package-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ddg-checkout-packages .package-left {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-left: 14px
}

.ddg-checkout-packages .package-name {
    font-weight: 700;
    margin-right: auto;
    width: 100%;
    padding: 10px 0
}

.ddg-checkout-packages .package-name .mdi-package {
    font-size: 22px;
    display: inline-block;
    height: 0;
    line-height: 0;
    position: relative;
    top: 3px;
    margin-right: 8px;
    color: #ccc
}

.ddg-checkout-packages .package-price {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0 20px
}

.ddg-checkout-packages .package-right {
    text-align: center;
    position: relative;
    padding-left: 15px;
    padding-right: 10px
}

.ddg-checkout-packages .package-right:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    height: calc(100% - 20px);
    margin: auto 0;
    width: 1px;
    background: #f0f0f0
}

.ddg-checkout-packages .package-right-qty {
    min-width: 32px;
    height: 50px;
    margin: 5px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative
}

.ddg-checkout-packages .package-right-qty.disableQuantity button {
    display: none!important
}

.ddg-checkout-packages .package-right-qty form {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 32px
}

@-webkit-keyframes ndzncheckoutbtn1 {
    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    50% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px)
    }
}

@keyframes ndzncheckoutbtn1 {
    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    50% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px)
    }
}

@-webkit-keyframes ndzncheckoutbtn2 {
    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    50% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px)
    }
}

@keyframes ndzncheckoutbtn2 {
    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    50% {
        -webkit-transform: translateY(3px);
        transform: translateY(3px)
    }
}

.ddg-checkout-packages .package-right-qty form:first-child {
    top: -6px
}

.ddg-checkout-packages .package-right-qty form:first-child button:hover i {
    -webkit-animation: ndzncheckoutbtn1 .4s infinite linear;
    animation: ndzncheckoutbtn1 .4s infinite linear
}

.ddg-checkout-packages .package-right-qty form:last-child {
    bottom: -5px
}

.ddg-checkout-packages .package-right-qty form:last-child button:hover i {
    -webkit-animation: ndzncheckoutbtn2 .4s infinite linear;
    animation: ndzncheckoutbtn2 .4s infinite linear
}

.ddg-checkout-packages .package-right-qty fieldset[disabled] {
    opacity: .4;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ddg-checkout-packages .package-right-qty button {
    width: 100%;
    height: 30px;
    min-width: 32px;
    cursor: pointer;
    line-height: 30px
}

.ddg-checkout-packages .package-right-qty span {
    display: block;
    font-weight: 700;
    margin: -9px 0 -9px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ddg-checkout-packages .package-right-btn {
    display: block;
    width: 32px;
    height: 42px;
    line-height: 42px;
    font-size: 19px;
    text-align: center
}

.ddg-checkout-packages .package-right-btn .fa-info {
    font-size: 18px
}

.ddg-checkout-packages .package-right-btn.info {
    color: #7979ea
}

.ddg-checkout-packages .package-right-btn.remove {
    color: #da3f3f
}

.ddg-checkout-packages .package-container.options {
    padding: 0 12px 12px 20px
}

.ddg-checkout-packages .options-box {
    background: #f0f0f0;
    border-radius: 6px;
    padding: 10px 14px;
    position: relative
}

.ddg-checkout-packages .options-box:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 25px;
    display: block;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-bottom-color: #f0f0f0
}

.ddg-checkout-packages .options-box .opt {
    color: #666;
    font-size: 13px;
    padding: 2px 0
}

.ddg-checkout-packages .options-box .opt:not(:last-child) {
    margin-bottom: 5px
}

@media (max-width:1000px) and (min-width:850.5px),
(max-width:770px) {
    .ddg-checkout-packages .package-left {
        padding-left: 20px
    }
    .ddg-checkout-packages .package-name .mdi-package {
        display: none
    }
}

@media (max-width:650px) {
    .ddg-checkout-packages .package-left {
        display: block;
        padding: 10px 20px 10px 14px
    }
    .ddg-checkout-packages .package-name {
        padding: 0
    }
    .ddg-checkout-packages .package-price {
        padding: 0
    }
    .ddg-checkout-packages .package-container.options {
        padding: 0 10px 10px
    }
}

@media (max-width:400px) {
    .ddg-checkout-packages .package-container.main {
        display: block;
        text-align: center
    }
    .ddg-checkout-packages .package-left {
        padding-bottom: 0
    }
    .ddg-checkout-packages .package-right {
        margin: -6px auto -4px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        padding: 0
    }
    .ddg-checkout-packages .package-right:before {
        display: none
    }
    .ddg-checkout-packages .options-box:before {
        left: 0;
        right: 0;
        margin: 0 auto
    }
}

:root {
    --spacing: 20px
}

@media (max-width:900px) {
    :root {
        --spacing: 15px
    }
}

@media (max-width:650px) {
    :root {
        --spacing: 8px
    }
}

body,
button,
input,
option,
select,
textarea {
    font-family: Montserrat, sans-serif;
    font-weight: 500
}

body {
    background: #0d120e;
    font-weight: 500;
    color: #97ad9f
}

#ddg-page {
    margin-top: var(--spacing)
}

#ddg-page .page-sidebar .panel-default {
    margin: var(--spacing) 0 0
}

.panel-default {
    border-radius: 6px;
    border: none;
    background: #16261c
}

.panel-default .panel-heading {
    background: 0 0;
    border: none;
    padding: 25px 25px 6px;
    border-radius: 6px 6px 0 0;
    font-family: Minecrafter2, sans-serif;
    letter-spacing: .035em;
    font-weight: 100;
    position: relative;
    font-size: 17px;
    color: #fff
}

.panel-default .panel-heading:before {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 25px;
    height: 2px;
    background: #f6e45d
}

.panel-default .panel-body {
    border-radius: 0 0 6px 6px;
    padding: 30px
}

@media (max-width:650px) {
    .panel-default .panel-body {
        padding: 15px
    }
}

.panel-default .panel-body p {
    display: block;
    line-height: 28px;
    text-align: justify
}

.panel-default .panel-body p a {
    color: #f6e45d
}

.panel-default .panel-body p:last-child {
    margin-bottom: 0
}

.ddg-login-tip {
    margin-bottom: 10px;
    font-size: 13px;
    display: block
}

.panel--ddg-login .username {
    margin: 0
}

.panel--ddg-checkout-header {
    padding-bottom: 19px
}

.btn {
    border: none;
    border-radius: 4px;
    outline: 0!important;
    font-size: 13px;
    padding: 0 12px;
    height: 34px;
    line-height: 34px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .1)
}

.btn.btn-lg {
    font-size: 15px;
    height: 46px;
    line-height: 46px;
    padding: 0 20px
}

.btn.btn-success {
    background: #2a6335
}

.btn.btn-success:focus,
.btn.btn-success:hover {
    background: #24552d
}

.btn.btn-warning {
    background: #f6e45d;
    color: #635a1c
}

.btn.btn-warning:focus,
.btn.btn-warning:hover {
    background: #d6c757
}

.form-control {
    border-radius: 4px !important;
background: #101d15 !important;
background-color: transparent !important;
border: 1px solid #222e26 !important;
color: #fff !important;
}

.form-control[readonly] {
    cursor: not-allowed;
    background-color: #101d15 !important;
opacity: 1;
}

.form-control::-webkit-input-placeholder {
    color: #374a3f
}

.form-control::-moz-placeholder {
    color: #374a3f
}

.form-control:-ms-input-placeholder {
    color: #374a3f
}

.form-control::-ms-input-placeholder {
    color: #374a3f
}

.form-control::placeholder {
    color: #374a3f
}

.form-control:focus {
    border-color: #2a6335
}

.input-group-addon {
    background: #101d15;
    border-color: #222e26;
    color: #324c3c;
    font-weight: 700;
    border-left: none
}

#ddg-page .page-main .panel-default:first-child {
    background: #16261c url(/assets/images/element-heading.png) no-repeat top left
}

#ddg-sidebar-nav .nav-items {
    padding: 0
}

#ddg-sidebar-nav .nav-items>li>a {
    color: #fff;
    display: block;
    background: #16261c;
    border-radius: 6px;
    position: relative;
    padding: 15px 15px 14px;
    border-bottom: 1px solid rgba(255, 255, 255, .04)
}

#ddg-sidebar-nav .nav-items>li:not(:first-child)>a {
    margin-top: 4px
}

#ddg-sidebar-nav .nav-items>.active>a {
    color: #f6e45d;
    background: #16261c url(/assets/images/element-heading.png) no-repeat top left
}

#ddg-sidebar-nav .nav-items>.active>a:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: calc(100% - 19px);
    border-radius: 0 3px 3px 0;
    width: 3px;
    background: #f6e45d
}

#ddg-sidebar-nav .nav-items .nav-dd {
    background: #132118;
    border-radius: 0 0 6px 6px;
    margin: 0 6px 6px
}

#ddg-sidebar-nav .nav-items .nav-dd a {
    color: #789b85;
    padding: 10px 16px;
    -webkit-transition: .15s;
    -o-transition: .15s;
    transition: .15s
}

#ddg-sidebar-nav .nav-items .nav-dd a:focus,
#ddg-sidebar-nav .nav-items .nav-dd a:hover {
    color: #95b19f
}

#ddg-sidebar-nav .nav-items .nav-dd a:nth-child(even) {
    background: rgba(0, 0, 0, .075)
}

.page-header {
    padding: 15px 16px 14px;
    border: none;
    display: table;
    border-radius: 6px;
    background: rgba(0, 0, 0, .15)
}

.page-header h4 {
    color: #f6e45d;
    margin: 0;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 700
}

.ddg-checkout-packages .package:not(:last-child) {
    border-color: rgba(255, 255, 255, .04)
}

.ddg-checkout-packages .package .package-right:before {
    background: rgba(255, 255, 255, .04) !important;
}

.ddg-checkout-packages .package-name,
.ddg-checkout-packages .package-name .mdi-package {
    color: #b6ccbe
}

.ddg-checkout-packages .package-price {
    font-weight: 700;
    font-size: 13px
}

.ddg-checkout-packages .options-box {
    background: #101d15
}

.ddg-checkout-packages .options-box:before {
    border-bottom-color: #101d15
}

.ddg-checkout-packages .options-box .opt {
    color: rgba(255, 255, 255, .4)
}

.checkout a {
    color: #fff;
    font-weight: 700
}

.checkout .redeemed table {
    border: 1px solid #27362c
}

.checkout .redeemed td {
    background: rgba(255, 255, 255, .025) !important;
    border-color: rgba(255, 255, 255, .05) !important
}

.checkout .redeemed .btn {
    padding: 0;
    line-height: 24px;
    height: 24px;
    width: 24px
}

.orderstatus td {
    border-color: #27362c !important;
}

.orderstatus hr {
    border-color: #27362c;
}

.orderstatus .table.well {
    background: rgba(255, 255, 255, .025);
    border-color: #2b3336;
}

.orderstatus .table.well td.name {
    color: #f6e45d;
    background: #27362c;
}

.orderstatus .table.well td {
    background: 0 0 !important;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th,
hr {
    border-color: rgba(255, 255, 255, .04)
}

#ddg-packages-images .package {
    border: none
}

#ddg-packages-images .package .package-name {
    color: #fff
}

#ddg-packages-images .package .package-price {
    color: #78c094;
    margin: 0 0 12px
}

#ddg-packages-images .package .package-price .price-discount {
    color: red
}

#ddg-packages-images .package .package-expires {
    background: #c4312d;
    color: #fff;
    font-weight: 700;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .2)
}

@media (max-width:460px) {
    #ddg-packages-images .package .package-expires {
        font-size: 11px;
        padding: 4px 8px;
        right: auto;
        top: auto;
        position: relative;
        margin: 4px auto 10px
    }
}

.panel-body--category-list {
    padding: 0!important
}

.panel-body--category-list:not(:first-child) {
    margin-top: 22px
}

#ddg-packages-list .list-item {
    border: none;
    padding: 15px;
    padding-left: 20px
}

#ddg-packages-list .list-item .item-name {
    color: #fff
}

#ddg-packages-list .list-item .item-price {
    font-weight: 700;
    font-size: 13px;
    color: #78c094
}

#ddg-packages-list .list-item .item-price .price-discount {
    color: red
}

#ddg-packages-list .list-item .item-expires {
    background: #c4312d;
    color: #fff;
    font-weight: 700;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .2)
}

@media (max-width:600px) {
    #ddg-packages-list .list-item .item-expires {
        position: relative;
        top: auto;
        right: auto;
        margin-top: 5px
    }
}

#ddg-packages-list .list-item:not(:last-child) {
    margin-bottom: 0
}

#ddg-packages-list .list-item:nth-child(odd) {
    background: rgba(0, 0, 0, .05)
}

.modal-content {
    border-radius: 5px;
    background: #16261c;
    border: 2px solid rgba(255, 255, 255, .025)
}

.modal-header {
    border: none
}

.modal-header .close {
    color: #f6e45d
}

.modal-title {
    color: #fff;
    font-weight: 700;
    font-size: 18px
}

.modal-footer {
    border: none
}

.modal-footer .pull-left {
    color: #f6e45d;
    padding: 0 5px
}

.modal-footer .btn-success {
    background: #3f9d3f
}

.modal-footer .btn-success:focus,
.modal-footer .btn-success:hover {
    background: #398e39
}

.modal-footer .btn-gift {
    background: #b94a82
}

.modal-footer .btn-gift:focus,
.modal-footer .btn-gift:hover {
    background: #ac4378
}

.modal-footer form .form-control {
    font-weight: 700;
    border-radius: 4px!important
}

.modal-footer form .btn {
    background: #f6e45d;
    color: #635a1c;
    font-weight: 700;
    width: 50px;
    border-radius: 4px!important;
    margin-left: 5px!important
}

.modal-footer form .btn:focus,
.modal-footer form .btn:hover {
    background: #f5e045
}

#ddg-above-header-warning {
    background: #d03030;
    padding: 18px 0 20px;
    color: #fff;
    text-align: justify;
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0 4px rgba(0, 0, 0, .1);
    box-shadow: 0 4px rgba(0, 0, 0, .1)
}

#ddg-above-header-warning .container {
    padding-right: 70px;
    position: relative
}

#ddg-above-header-warning span {
    font-size: 13px;
    line-height: 18px;
    display: block
}

#ddg-above-header-warning .close {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    opacity: 1;
    color: rgba(255, 255, 255, .85);
    right: 0;
    text-shadow: 2px 2px rgba(0, 0, 0, .2)
}

@media (max-width:750px) {
    #ddg-above-header-warning .container {
        padding: 0;
        text-align: center!important
    }
    #ddg-above-header-warning span {
        text-align: center
    }
    #ddg-above-header-warning .close {
        position: unset;
        margin: 10px auto -10px;
        float: none;
        width: auto;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
    #ddg-above-header-warning .close:after {
        margin-left: 3px;
        font-size: 14px;
        content: "Verbergen"
    }
}

.ddg-default-footer {
    margin-top: 15px;
    border: none
}

.ddg-default-footer .container {
    padding: 35px 0
}

.ddg-default-footer span {
    color: #555f57;
    font-weight: 600;
    font-size: 13px
}

.ddg-default-footer span small {
    color: #3b403c;
    font-size: 11px;
    display: block;
    margin-top: 4px
}

.ddg-default-footer #tm {
    opacity: .3
}

@media (max-width:850px) {
    #ddg-page {
        margin-top: 0
    }
    .ddg-body {
        margin-top: -24px;
        position: relative;
        z-index: 2
    }
    #ddg-sidebar-nav .nav-header {
        background: #2a6335;
        border-radius: 6px;
        padding: 15px 15px 15px 25px;
        margin-bottom: 4px
    }
    #ddg-sidebar-nav .nav-header .nav-toggle {
        background: 0 0;
        color: #fff;
        border: none;
        line-height: 40px
    }
    #ddg-sidebar-nav .nav-header .mdi-menu {
        font-size: 40px
    }
    #ddg-sidebar-nav .nav-header .nav-title {
        color: #fff
    }
}

.swal-title {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: -5px;
    font-weight: 700;
    font-size: 28px
}

.swal-button-container {
    width: 100%;
    text-align: center;
    padding-bottom: 10px
}

.swal-button {
    background: #2a6335;
    height: 45px;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: .02em;
    text-shadow: 0 2px rgba(0, 0, 0, .1);
    border-radius: 100px;
    margin: 0 auto;
    padding: 0 40px
}

.swal-button:focus,
.swal-button:hover {
    background: #265a30!important
}

#ddg-header {
    height: 335px;
    position: relative;
    background: url(/assets/images/header.png) no-repeat top center/cover;
    -webkit-box-shadow: 0 5px #0c110d;
    box-shadow: 0 5px #0c110d;
    margin-bottom: 34px
}

#ddg-header .nav-menuTrigger {
    font-size: 35px;
    padding: 0 10px;
    height: 50px;
    text-align: center;
    text-decoration: none;
    line-height: 50px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#ddg-header .nav-menuTrigger b {
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    margin-left: 15px;
    display: block;
    height: 50px;
    padding-top: 1px
}

@media (max-width:370px) {
    #ddg-header .nav-menuTrigger b {
        display: none
    }
}

@media (min-width:900.5px) {
    #ddg-header .nav-menuTrigger {
        display: none
    }
}

#ddg-header .header-nav,
#ddg-header .header-splash {
    font-family: Minecrafter2, sans-serif
}

#ddg-header .header-nav {
    position: relative;
    margin-top: 45px
}

#ddg-header .header-nav .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#ddg-header .header-nav .p-body-inner {
    position: relative
}

#ddg-header .header-nav .nav-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#ddg-header .header-nav .nav-left .nav-left-btn {
    height: 42px;
    line-height: 42px;
    text-align: center;
    width: 42px;
    text-decoration: none;
    display: inline-block;
    color: #fff;
    background: rgba(255, 255, 255, .065);
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    border-radius: 4px;
    -webkit-transition: .15s;
    -o-transition: .15s;
    transition: .15s
}

#ddg-header .header-nav .nav-left .nav-left-btn:focus,
#ddg-header .header-nav .nav-left .nav-left-btn:hover {
    background: rgba(255, 255, 255, .1)
}

#ddg-header .header-nav .nav-left .nav-left-btn.is-menuOpen {
    background: rgba(255, 255, 255, .2)
}

#ddg-header .header-nav .nav-left .nav-left-btn:not(:first-child) {
    margin-left: 6px
}

#ddg-header .header-nav .nav-left .nav-left-btn b,
#ddg-header .header-nav .nav-left .nav-left-btn span {
    display: none
}

#ddg-header .header-nav .nav-left .is--w_new {
    font-size: 17px
}

#ddg-header .header-nav .nav-left .is--discord {
    font-size: 19px
}

#ddg-header .header-nav .nav-left .is--discord .mdi {
    position: relative;
    top: 1px
}

#ddg-header .header-nav .nav-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 145px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    margin: 0 auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    right: 0
}

#ddg-header .header-nav .nav-items .side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute
}

#ddg-header .header-nav .nav-items .side.side--left {
    right: 100%;
    margin-right: 25px
}

#ddg-header .header-nav .nav-items .side.side--right {
    left: 100%;
    margin-left: 25px
}

#ddg-header .header-nav .nav-items .nav-link {
    height: 60px;
    line-height: 60px;
    font-size: 19px;
    color: #e0e0e0;
    letter-spacing: 1px;
    padding: 0 27px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    text-shadow: 0 3px 0 rgba(0, 0, 0, .2);
    -webkit-transition: .15s;
    -o-transition: .15s;
    transition: .15s
}

#ddg-header .header-nav .nav-items .nav-link.is--store {
    background: #f6e45d;
    border-radius: 4px;
    width: 145px;
    text-align: center;
    padding: 0;
    font-size: 22px;
    line-height: 63px;
    position: relative;
    text-shadow: 0 2px 2px rgba(0, 0, 0, .05);
    z-index: 2;
    color: #635a1c
}

#ddg-header .header-nav .nav-items .nav-link.is--store:focus,
#ddg-header .header-nav .nav-items .nav-link.is--store:hover {
    background: #f7e770;
    color: #6f651f
}

#ddg-header .header-nav .nav-items .nav-link.active,
#ddg-header .header-nav .nav-items .nav-link:focus,
#ddg-header .header-nav .nav-items .nav-link:hover {
    color: #fff
}

#ddg-header .header-nav .nav-items .nav-link.active {
    position: relative
}

#ddg-header .header-nav .nav-items .nav-link.active:after {
    content: '';
    display: block;
    position: absolute;
    background: #808180;
    height: 4px;
    width: 32px;
    border-radius: 5px;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: 0 auto
}

#ddg-header .header-nav .nav-items .nav-link .mdi {
    margin-right: -12px;
    display: inline-block
}

#ddg-header .header-nav .nav-items .dropdown.open .nav-link {
    color: #ffffc4
}

#ddg-header .header-nav .nav-items .dropdown.open .nav-link .mdi {
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1)
}

#ddg-header .header-nav .nav-items .dropdown-menu {
    border: none;
    min-width: auto;
    text-align: center;
    padding: 5px 0;
    margin: 0;
    background: #2a6335
}

#ddg-header .header-nav .nav-items .dropdown-menu:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: #2a6335
}

#ddg-header .header-nav .nav-items .dropdown-menu a {
    color: #fff;
    display: block;
    font-size: 15px;
    height: 36px;
    line-height: 37px;
    text-decoration: none
}

#ddg-header .header-nav .nav-items .dropdown-menu a:focus,
#ddg-header .header-nav .nav-items .dropdown-menu a:hover {
    background: rgba(0, 0, 0, .1)
}

#ddg-header .header-nav .nav-right {
    margin-left: auto
}

#ddg-header .header-nav .nav-right .logout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 26px;
    line-height: 26px;
    color: #fff;
    font-weight: 100;
    font-size: 0;
    text-decoration: none
}

#ddg-header .header-nav .nav-right .logout b {
    font-size: 11px;
    letter-spacing: .065em;
    font-weight: 100;
    height: 23px;
    line-height: 23px
}

#ddg-header .header-nav .nav-right .logout img {
    position: relative;
    top: -2px;
    width: 23px;
    height: 23px;
    margin-left: 7px;
    border: 2px solid rgba(255, 255, 255, .1)
}

#ddg-header .header-nav .nav-right .guest {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: .5
}

#ddg-header .header-nav .nav-right .guest b {
    opacity: .75
}

#ddg-header .header-nav .nav-right .tooltip {
    font-family: Montserrat, sans-serif;
    font-size: 11px
}

#ddg-header .header-splash {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 203px
}

#ddg-header .header-splash .p-body-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 10px
}

#ddg-header .header-splash .divider {
    background: 0 0;
    height: 50px;
    width: 420px
}

#ddg-header .header-splash .splash-box {
    width: 300px;
    cursor: pointer;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

#ddg-header .header-splash .splash-box .big {
    -webkit-transition: .15s;
    -o-transition: .15s;
    transition: .15s
}

#ddg-header .header-splash .splash-box .small {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

@media (min-width:650.5px) {
    #ddg-header .header-splash .splash-box.splash-minecraft:hover {
        opacity: .8
    }
    #ddg-header .header-splash .splash-box.splash-minecraft:hover .big,
    #ddg-header .header-splash .splash-box.splash-minecraft:hover .small {
        -webkit-transform: translateX(-7px);
        -ms-transform: translateX(-7px);
        transform: translateX(-7px)
    }
    #ddg-header .header-splash .splash-box.splash-discord:hover {
        opacity: .8
    }
    #ddg-header .header-splash .splash-box.splash-discord:hover .big,
    #ddg-header .header-splash .splash-box.splash-discord:hover .small {
        -webkit-transform: translateX(7px);
        -ms-transform: translateX(7px);
        transform: translateX(7px)
    }
}

#ddg-header .header-splash .splash-box.splash-minecraft {
    text-align: right;
    position: relative
}

#ddg-header .header-splash .splash-box.splash-minecraft .big {
    color: #f6e45d
}

#ddg-header .header-splash .splash-box.splash-minecraft .small {
    color: #645d29;
    background: #f6e45d
}

#ddg-header .header-splash .splash-box.splash-discord .big {
    color: #9abcfa;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

#ddg-header .header-splash .splash-box.splash-discord .big .mdi-discord {
    margin-right: 8px
}

#ddg-header .header-splash .splash-box.splash-discord .small {
    color: #3f5987;
    background: #9abcfa
}

#ddg-header .header-splash .splash-box .dot {
    background: #f6e45d;
    height: 4px;
    width: 4px;
    display: inline-block;
    margin: 0 4px
}

#ddg-header .header-splash .splash-box .big {
    font-size: 22px;
    letter-spacing: 2px;
    text-shadow: 0 3px 0 rgba(0, 0, 0, .2)
}

#ddg-header .header-splash .splash-box .small {
    font-size: 11px;
    letter-spacing: 1px;
    display: inline-block;
    height: 25px;
    line-height: 26px;
    padding: 0 10px;
    border-radius: 4px;
    margin-top: 5px
}

#ddg-header .header-logo {
    background: url(/assets/images/header-logo.png) no-repeat center/auto 100%;
    height: 278px;
    width: 334px;
    margin: 0 auto;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 2;
    cursor: pointer
}

#ddg-header .header-green-bar {
    position: absolute;
    top: 300px;
    left: 0;
    right: 0;
    z-index: 1
}

#ddg-header .header-green-bar .container {
    background: #2a6335;
    height: 70px;
    border-radius: 6px;
    padding: 0
}

#ddg-header .header-flaires {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 144px;
    max-width: 1180px;
    margin: 0 auto;
    overflow: hidden
}

#ddg-header .header-flaires .flaire {
    height: 110px;
    width: 640px;
    position: absolute;
    bottom: 34px
}

#ddg-header .header-flaires .flaire.flaire-yellow {
    background: url(/assets/images/header-playercount.png) no-repeat bottom center/100% auto
}

#ddg-header .header-flaires .flaire.flaire-blue {
    background: url(/assets/images/header-discord.png) no-repeat bottom center/100% auto;
    right: 0
}

@media (max-width:1100px) {
    #ddg-header {
        height: 325px;
        margin-bottom: 12px
    }
    #ddg-header .header-nav {
        margin-top: 20px
    }
    #ddg-header .header-logo {
        height: 220px;
        width: 270px
    }
    #ddg-header .header-splash {
        top: 200px
    }
    #ddg-header .header-splash .divider {
        width: 320px
    }
    #ddg-header .header-splash .splash-box {
        width: 235px
    }
    #ddg-header .header-splash .splash-box .big {
        font-size: 16px
    }
    #ddg-header .header-splash .splash-box .small {
        height: 22px;
        line-height: 23px;
        padding: 0 8px
    }
    #ddg-header .header-green-bar {
        top: 290px
    }
    #ddg-header .header-green-bar .container {
        height: 48px
    }
}

@media (max-width:1020px) {
    #ddg-header .header-nav .nav-items .nav-link {
        font-size: 18px;
        height: 45px;
        line-height: 46px;
        padding: 0 18px
    }
    #ddg-header .header-nav .nav-items .nav-link.is--store {
        font-size: 18px;
        width: 125px;
        line-height: 46px
    }
    #ddg-header .header-nav .nav-items {
        width: 125px
    }
    #ddg-header .header-nav .nav-items .side.side--left {
        margin-right: 15px
    }
    #ddg-header .header-nav .nav-items .side.side--right {
        margin-left: 15px
    }
}

@media (max-width:1000px) {
    #ddg-header {
        height: 275px
    }
    #ddg-header .header-logo {
        height: 160px;
        width: 220px
    }
    #ddg-header .header-splash {
        top: 160px
    }
    #ddg-header .header-splash .divider {
        width: 240px
    }
    #ddg-header .header-flaires {
        bottom: -10px
    }
    #ddg-header .header-green-bar {
        top: 250px
    }
}

@media (min-width:900px) {
    #ddg-header .header-nav .nav-left .nav-left-btn.is--b_forums {
        display: none
    }
}

@media (max-width:900px) {
    #ddg-header {
        position: relative;
        height: 170px;
        margin-bottom: 23px
    }
    #ddg-header .header-logo {
        display: none
    }
    #ddg-header .header-nav {
        margin-top: 0;
        background: rgba(0, 0, 0, .2)
    }
    #ddg-header .header-nav,
    #ddg-header .header-nav .p-body-inner {
        position: unset
    }
    #ddg-header .header-nav .p-body-inner {
        height: 70px
    }
    #ddg-header .header-nav .container {
        padding: 0
    }
    #ddg-header .header-nav .nav-items {
        display: none
    }
    #ddg-header .header-nav .nav-left {
        position: unset;
        z-index: 2;
        bottom: -23px;
        left: var(--spacing);
        font-family: Montserrat, sans-serif;
        width: calc(100% - calc(var(--spacing) * 2));
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0
    }
    #ddg-header .header-nav .nav-left .nav-left-btn {
        background: unset;
        width: unset;
        height: 48px;
        line-height: 48px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        padding: 0 5px;
        font-weight: 700;
        font-size: 13px;
        margin: 0
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--discord {
        display: none
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--b_forums,
    #ddg-header .header-nav .nav-left .nav-left-btn.is--w_new {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--b_forums .fa-bolt,
    #ddg-header .header-nav .nav-left .nav-left-btn.is--b_forums .mdi,
    #ddg-header .header-nav .nav-left .nav-left-btn.is--w_new .fa-bolt,
    #ddg-header .header-nav .nav-left .nav-left-btn.is--w_new .mdi {
        margin-left: 0;
        font-size: 20px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--b_forums span,
    #ddg-header .header-nav .nav-left .nav-left-btn.is--w_new span {
        font-size: 14px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--w_new {
        padding: 0 5px;
        margin-left: 15px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--w_new .fa-bolt {
        margin-right: 5px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--b_forums {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-left: 0
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--b_forums .mdi {
        margin-left: 0;
        font-size: 20px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--b_forums span {
        font-size: 14px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn .fa-search {
        margin-left: -3px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn .fa-bolt {
        margin-left: -1px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn b,
    #ddg-header .header-nav .nav-left .nav-left-btn span {
        display: inline-block;
        margin-left: 5px
    }
    #ddg-header .header-splash {
        top: auto;
        bottom: 24px;
        height: auto
    }
    #ddg-header .header-splash .container {
        height: 75px;
        padding: 0 0 4px
    }
    #ddg-header .header-splash .divider {
        display: none
    }
    #ddg-header .header-splash .splash-box.splash-minecraft {
        text-align: left
    }
    #ddg-header .header-splash .splash-box.splash-discord {
        margin-left: auto;
        background: orange;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        border-radius: 5px;
        background: rgba(255, 255, 255, .05);
        overflow: hidden;
        -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
        box-shadow: 0 3px 5px rgba(0, 0, 0, .1)
    }
    #ddg-header .header-splash .splash-box.splash-discord .big,
    #ddg-header .header-splash .splash-box.splash-discord .small {
        display: none
    }
    #ddg-header .header-splash .splash-box.splash-discord:before {
        content: "\F66F";
        font-family: 'Material Design Icons';
        font-size: 27px;
        position: relative;
        top: 1px;
        color: #9abcfa
    }
    #ddg-header .header-flaires .flaire-yellow {
        right: 0;
        margin: 0 auto;
        width: 100%;
        background-size: 650px auto!important
    }
    #ddg-header .header-flaires .flaire-blue {
        display: none
    }
    #ddg-header .header-green-bar {
        top: auto;
        bottom: -23px
    }
}

@media (max-width:900px) and (min-width:650.5px) {
    #ddg-header .header-splash .splash-box.splash-minecraft:hover .big,
    #ddg-header .header-splash .splash-box.splash-minecraft:hover .small {
        -webkit-transform: translateX(7px);
        -ms-transform: translateX(7px);
        transform: translateX(7px)
    }
}

@media (max-width:850px) {
    #ddg-header {
        margin-bottom: 0
    }
    #ddg-header .header-nav .nav-left {
        padding: 0
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--w_new {
        display: none
    }
    #ddg-header .header-green-bar {
        display: none
    }
}

@media (max-width:650px) {
    #ddg-header .header-splash .splash-box.splash-minecraft {
        padding-top: 1px;
        margin: 0 auto;
        text-align: center
    }
    #ddg-header .header-splash .splash-box.splash-discord {
        display: none
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--discord {
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--discord:after {
        content: "Discord";
        margin-left: 8px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--discord .mdi-discord {
        font-size: 20px
    }
    #ddg-header .header-nav .nav-left .nav-left-btn.is--b_forums span {
        font-size: 13px
    }
}

@media (max-width:400px) {
    #ddg-header .header-nav .nav-left .nav-left-btn.is--discord {
        display: none
    }
}

#ddg-home {
    padding: 50px
}

@media (max-width:1100px) {
    #ddg-home {
        padding: 30px
    }
}

#ddg-home .btn {
    font-weight: 700
}

#ddg-home .btn .mdi {
    font-size: 1.3em;
    height: 1em;
    line-height: 1em;
    position: relative;
    top: .125em;
    margin-left: -3px;
    margin-right: 7px
}

#ddg-home .home-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 35px
}

#ddg-home .home-header .home-goal {
    margin-left: auto;
    position: relative;
    top: -4px
}

#ddg-home .home-header .home-goal .goal-text {
    color: #f6e45d;
    font-weight: 700;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    letter-spacing: .08em;
    margin-bottom: 6px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#ddg-home .home-header .home-goal .goal-text small {
    color: #f6e45d;
    margin-left: auto;
    font-size: 12px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    opacity: 1
}

#ddg-home .home-header .home-goal .goal-text small:empty {
    opacity: 0
}

#ddg-home .home-header .home-goal .goal-bar {
    width: 280px;
    height: 20px;
    border-radius: 6px;
    position: relative
}

#ddg-home .home-header .home-goal .goal-bar:before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, .2);
    border-radius: 4px;
    height: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    position: absolute
}

#ddg-home .home-header .home-goal .goal-bar .goal-bar-inner {
    background: #f6e45d;
    height: 20px;
    position: absolute;
    border-radius: 4px;
    min-width: 3%;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s
}

#ddg-home .home-header .home-title {
    font-weight: 100;
    color: #f6e45d;
    font-size: 32px;
    letter-spacing: .02em;
    font-family: Minecrafter2, sans-serif
}

#ddg-home p {
    font-size: 15px;
    color: #84a788;
    line-height: 26px;
    font-weight: 700;
    display: block;
    text-align: justify
}

#ddg-home .home-gateways {
    margin-top: 30px;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

#ddg-home .home-gateways .gateway {
    border-radius: 6px;
    background: #111f16;
    text-align: center;
    padding: 25px;
    padding-left: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#ddg-home .home-gateways .gateway:first-child {
    margin-bottom: 15px
}

#ddg-home .home-gateways .gateway .gateway-icon {
    width: auto;
    margin-right: auto
}

#ddg-home .home-gateways .gateway .gateway-icon.icon--paypal {
    height: 32px
}

#ddg-home .home-gateways .gateway .gateway-icon.icon--mollie {
    height: 28px
}

#ddg-home .home-gateways .gateway .gateway-methods {
    font-size: 0
}

#ddg-home .home-divider {
    color: #fff;
    font-weight: 100;
    margin: 55px 0 26px;
    font-size: 21px;
    letter-spacing: .025em;
    position: relative;
    font-family: Minecrafter2, sans-serif
}

#ddg-home .home-divider:after {
    content: '';
    display: block;
    position: absolute;
    background: #fff;
    bottom: -5px;
    left: 0;
    height: 3px;
    width: 27px
}

#ddg-home .home-bluecolour p,
#ddg-home .home-redcolour p {
    margin-bottom: 15px
}

#ddg-home .home-bluecolour .home-btns .btn,
#ddg-home .home-redcolour .home-btns .btn {
    height: 40px;
    line-height: 39px;
    padding: 0 15px
}

#ddg-home .home-bluecolour .home-btns .btn:not(:first-child),
#ddg-home .home-redcolour .home-btns .btn:not(:first-child) {
    margin-left: 5px
}

#ddg-home .home-btns {
    font-size: 0
}

#ddg-home .home-btns .btn {
    font-size: 13px;
    background: #3d6f50;
    border: none;
    color: #a9edc3;
    border-bottom: 3px solid rgba(0, 0, 0, .1)
}

@media (max-width:1000px) {
    #ddg-home .home-header .home-title {
        margin: 0 auto
    }
    #ddg-home .home-header .home-goal {
        display: none
    }
    #ddg-home p {
        text-align: center;
        font-size: 14px;
        line-height: 24px
    }
    #ddg-home .home-divider {
        text-align: center
    }
    #ddg-home .home-divider:after {
        height: 3px;
        top: calc(100% + 3px);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto
    }
    #ddg-home .home-btns {
        text-align: center
    }
}

@media (max-width:650px) {
    #ddg-home .home-header {
        margin-bottom: 20px
    }
    #ddg-home .home-gateways {
        margin: 20px 0 -10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
    #ddg-home .home-gateways .gateway {
        margin: 3px 0!important
    }
}

@media (max-width:400px) {
    #ddg-home {
        padding-left: 20px;
        padding-right: 20px
    }
    #ddg-home .home-btns .btn {
        margin: 3px auto!important;
        display: table;
        width: 100%;
        max-width: 200px
    }
}

#ddg-featuredpackages {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: var(--spacing)
}

#ddg-featuredpackages .package {
    cursor: pointer;
    background: #f6e45d;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px;
    width: 100%;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    -webkit-box-shadow: 0 0 0 3px transparent;
    box-shadow: 0 0 0 3px transparent
}

#ddg-featuredpackages .package:hover {
    opacity: .875;
    -webkit-transform: scale(.99);
    -ms-transform: scale(.99);
    transform: scale(.99);
    -webkit-box-shadow: 0 0 0 5px rgba(0, 0, 0, .25);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, .25)
}

#ddg-featuredpackages .package:not(:last-child) {
    margin-right: 10px
}

#ddg-featuredpackages .package .package-img {
    background: no-repeat center/100% auto;
    height: 50px;
    background-color: #f6e45d;
    border-radius: 8px;
    margin-right: 15px
}

#ddg-featuredpackages .package .package-text {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: auto
}

#ddg-featuredpackages .package .package-text .name,
#ddg-featuredpackages .package .package-text .price {
    display: block;
    font-weight: 600
}

#ddg-featuredpackages .package .package-text .name {
    color: #635a1c;
    font-size: 14px;
    text-align: center;
    font-weight: 700
}

@media (min-width:1250px) {
    #ddg-featuredpackages .package .package-text .name {
        text-align: right;
        width: 180px;
        max-width: 100%;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap
    }
}

#ddg-featuredpackages .package .package-text .price {
    font-size: 12px;
    margin-top: 2px;
    color: #635a1c
}

#ddg-featuredpackages .package .package-text .discount {
    position: relative;
    opacity: .7
}

#ddg-featuredpackages .package .package-text .discount:before {
    content: '';
    height: 2px;
    width: calc(100% + 4px);
    position: absolute;
    display: block;
    background: rgba(99, 90, 28, .7);
    top: 8px;
    right: -2px
}

@media (max-width:1250px) {
    #ddg-featuredpackages .package {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
    #ddg-featuredpackages .package .package-img {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }
    #ddg-featuredpackages .package .package-text {
        margin: 0;
        text-align: center;
        padding-top: 10px
    }
}

@media (max-width:850px) {
    #ddg-featuredpackages {
        display: none
    }
}

@media (max-width:650px) {
    #ddg-featuredpackages {
        display: none
    }
}

.ddg-module.module--topdonor {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 5px;
    background: #16261c url(/assets/images/element-heading.png) no-repeat top left
}

.ddg-module.module--topdonor .donor-text {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.ddg-module.module--topdonor .donor-text .big {
    color: #fff;
    font-size: 16px;
    padding-top: 3px;
    font-weight: 700;
    margin-bottom: 1px;
    text-shadow: 0 5px 10px rgba(0, 0, 0, .2)
}

.ddg-module.module--topdonor .donor-text .small {
    color: #516559;
    font-weight: 700;
    font-size: 11px
}

.ddg-module.module--topdonor .donor-img {
    display: inline-block;
    margin-left: 5px;
    overflow: hidden;
    height: 95px;
    position: relative;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    top: -7px;
    width: 77px;
    margin-bottom: -7px
}

.ddg-module.module--topdonor .donor-img img {
    width: 65px;
    overflow: hidden
}

.ddg-module.notopdonor img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%)
}

.ddg-module.notopdonor .donor-text .small {
    position: relative;
    top: 1px;
    font-size: 13px!important
}

.ddg-module.module--payments {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 14px 0;
    margin-top: 5px!important
}

.ddg-module.module--payments .payment-svg {
    margin: 0 16px 0 19px
}

.ddg-module.module--payments .payments-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ddg-module.module--payments img {
    border-radius: 5px
}

.ddg-module.module--payments .payment {
    margin-left: 5px
}

@media (max-width:900px) {
    .ddg-module {
        display: none!important
    }
}

.ddg-module.nopayments .nopayments-text {
    font-weight: 700;
    width: 100%;
    font-size: 12px;
    text-align: center;
    display: block;
    color: #516559
}

#ddg-login-modal .modal-dialog {
    width: 400px;
    max-width: calc(100% - 20px);
    margin: 0 auto
}

#ddg-login-modal .modal-content {
    padding: 30px
}

#ddg-login-modal .login-error {
    background: #b92d2d;
    padding: 15px;
    font-size: 13px;
    font-weight: 700;
    border-radius: 6px;
    color: #fff;
    text-shadow: 0 2px 2px rgba(0, 0, 0, .15);
    border: 2px solid rgba(0, 0, 0, .2);
    margin-bottom: 20px
}

#ddg-login-modal .login-error:empty {
    display: none
}

#ddg-login-modal .login-heading {
    font-family: Minecrafter2, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    color: #f6e45d;
    display: block;
}

#ddg-login-modal .login-subheading {
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    display: block;
    margin-bottom: 26px
}

#ddg-login-modal .login-heading span._green {
    color: #69e572
}

#ddg-login-modal .login-heading span._red {
    color: #ff6565
}

#ddg-login-modal .login-input {
    padding: 0;
    height: 42px;
    line-height: 43px;
    font-size: 16px;
    font-weight: 700;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 200px;
    text-align: center;
    max-width: 100%;
    margin: 0 auto
}

#ddg-login-modal .btn {
    outline: 0;
    height: 42px;
    line-height: 39px;
    padding: 0;
    display: table;
    width: 200px;
    font-weight: 700;
    margin: 4px auto 0
}

.ddg-component-cart .cart-toggle {
    background: #2a6335;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: var(--spacing);
    padding: 14px 14px 13px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width:900px) {
    .ddg-component-cart .cart-toggle {
        background-image: none!important
    }
}

.ddg-component-cart .cart-toggle .toggle-icon {
    padding: 8px;
    background: rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    margin-right: 14px;
    border-radius: 6px;
    position: relative
}

.ddg-component-cart .cart-toggle .toggle-icon span {
    display: block;
    position: absolute;
    top: -6px;
    left: -4px;
    color: #635a1c;
    font-weight: 900;
    font-size: 11px;
    height: 16px;
    line-height: 16px;
    padding: 0 10px;
    background: #f6e45d;
    border-radius: 100px
}

.ddg-component-cart .cart-toggle img {
    border-radius: 6px;
    height: 32px;
    width: 32px
}

.ddg-component-cart .cart-toggle .big {
    font-family: Minecrafter2, sans-serif;
    margin-bottom: -1px;
    text-transform: uppercase;
    display: block;
    font-size: 16px;
    letter-spacing: .03em
}

.ddg-component-cart .cart-toggle .small {
    font-size: 11px;
    font-weight: 700;
    display: block;
    margin-bottom: -1px;
    letter-spacing: -.02em;
    color: #f6e45d
}

.ddg-component-cart .cart-toggle.cart-toggle--guest {
    background: rgba(255, 255, 255, .1);
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid rgba(255, 255, 255, .035)
}

.ddg-component-cart .cart-toggle.cart-toggle--guest .toggle-icon {
    background: rgba(255, 255, 255, .05);
    border-color: rgba(255, 255, 255, .03)
}

.ddg-component-cart .cart-toggle.cart-toggle--guest .toggle-text .big {
    color: #888
}

.ddg-component-cart .cart-toggle.cart-toggle--guest .toggle-text .small {
    color: #777
}

@media (max-width:900px) {
    .ddg-component-cart .cart-dropdown {
        top: calc(100% - 8px);
        margin: 0 auto;
        right: 0;
        left: 0
    }
    .ddg-component-cart .cart-dropdown:after,
    .ddg-component-cart .cart-dropdown:before {
        left: 0;
        right: 0;
        margin: 0 auto
    }
}

@media (min-width:1180px) {
    body.body--ddg-ranks #ddg-page .page-sidebar,
    body.body--ddg-ranks .body {
        display: none
    }
}

@media (max-width:1180px) {
    body.body--ddg-ranks #ddg-ranks {
        display: none
    }
}

#ddg-ranks .ranks-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

#ddg-ranks .ranks-header .left {
    height: 75px;
    padding-top: 3px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#ddg-ranks .ranks-header .left a {
    height: 75px;
    line-height: 75px;
    width: 78px;
    text-align: center;
    font-size: 40px;
    color: #fff;
    position: relative
}

#ddg-ranks .ranks-header .left a:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(100% - 2px);
    margin: auto 0;
    width: 4px;
    height: 40px;
    border-radius: 50px;
    background: rgba(255, 255, 255, .05)
}

#ddg-ranks .ranks-header .left .tooltip {
    margin-top: 10px!important
}

#ddg-ranks .ranks-header .left span {
    padding-left: 25px;
    font-family: Minecrafter2, sans-serif;
    font-size: 26px;
    color: #fff
}

#ddg-ranks .ranks-header .right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 280px;
    flex: 0 0 280px;
    margin-left: var(--spacing)
}

#ddg-ranks .ranks-header .right .cart-toggle {
    margin-bottom: 0
}

#ddg-ranks .ranks-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative
}

#ddg-ranks .ranks-section.section--title {
    margin-bottom: 20px
}

#ddg-ranks .rank-title {
    text-align: center;
    color: #fff
}

#ddg-ranks .rank-title .rank-title-img {
    cursor: pointer
}

#ddg-ranks .rank-title img {
    max-width: 160px;
    height: auto;
    margin: 0 auto
}

#ddg-ranks .rank-title .name {
    font-size: 16px;
    font-weight: 700;
    display: block;
    margin: 10px 0 2px
}

#ddg-ranks .rank-title .price {
    font-weight: 700;
    font-size: 0
}

#ddg-ranks .rank-title .price div {
    display: inline-block;
    font-size: 13px
}

#ddg-ranks .rank-title .price .price-discount {
    margin-right: 5px;
    color: red;
    font-size: 12px;
    text-decoration: line-through
}

#ddg-ranks .rank-title .price .price-actual {
    color: #76b074
}

#ddg-ranks .rank-title .btn {
    margin-top: 8px;
    width: calc(100% - 50px);
    text-transform: uppercase;
    font-family: Minecrafter2, sans-serif;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: .05em
}

#ddg-ranks .ranks-section:nth-child(n+3) {
    margin-top: 50px
}

#ddg-ranks .ranks-col {
    width: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    position: relative
}

#ddg-ranks .ranks-col.ranks-col--labels .labels-title {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    color: #3c5741;
    letter-spacing: .02em;
    margin-bottom: 7px;
    text-transform: uppercase;
    font-family: Minecrafter2, sans-serif;
    font-size: 17px
}

#ddg-ranks .ranks-col.ranks-col--labels .ranks-col-row {
    color: #86a58b;
    background: #131d15;
    font-size: 13px
}

#ddg-ranks .ranks-col.ranks-col--labels .ranks-col-row:nth-child(odd) {
    background: #172319
}

#ddg-ranks .ranks-col-row {
    color: #fff;
    background: #16261c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    min-height: 58px;
    line-height: 20px;
    font-size: 15px;
    font-weight: 700;
    vertical-align: middle
}

#ddg-ranks .ranks-col-row:nth-child(odd) {
    background: #1a2c21
}

#ddg-ranks .ranks-col-row.row--checkbox:after {
    display: block;
    line-height: 0;
    height: 0;
    width: 24px;
    font-size: 24px;
    text-align: center;
    font-family: 'Material Design Icons';
    pointer-events: none
}

#ddg-ranks .ranks-col-row.row--checkbox.yes:after {
    content: "\F134";
    color: #f6e45d
}

#ddg-ranks .ranks-col-row.row--checkbox.no:after {
    content: "\F15A";
    color: #3a5545
}

@media (min-width:1650px) {
    #ddg-ranks .ranks-col-row:first-child {
        border-radius: 6px 6px 0 0
    }
    #ddg-ranks .ranks-col-row:last-child {
        border-radius: 0 0 6px 6px
    }
    #ddg-ranks .ranks-col.ranks-col--labels {
        position: absolute;
        top: 0;
        right: 100%;
        width: 200px
    }
    #ddg-ranks .ranks-col:not(:last-child) {
        margin-right: 8px
    }
}

@media (max-width:1650px) {
    #ddg-ranks .ranks-section .labels-rows .ranks-col-row:first-child {
        border-top-left-radius: 6px
    }
    #ddg-ranks .ranks-section .labels-rows .ranks-col-row:last-child {
        border-bottom-left-radius: 6px
    }
    #ddg-ranks .ranks-section .ranks-col:last-child .ranks-col-row:first-child {
        border-top-right-radius: 6px
    }
    #ddg-ranks .ranks-section .ranks-col:last-child .ranks-col-row:last-child {
        border-bottom-right-radius: 6px
    }
}
